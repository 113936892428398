define('ember-cli-tinymce/components/ember-cli-tinymce', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  //jshint camelcase:false
  //jscs:disable requireCamelCaseOrUpperCaseIdentifiers
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['text-editor'],

    extraButtons: [],
    conditionals: [],
    options: {},

    defaults: {
      skin_url: '/ember-cli-tinymce/tinymce/skins/lightgray',
      theme_url: '/ember-cli-tinymce/tinymce/themes/modern/theme.min.js',
      external_plugins: {
        image: '/ember-cli-tinymce/tinymce/plugins/image/plugin.min.js',
        link: '/ember-cli-tinymce/tinymce/plugins/link/plugin.min.js',
        table: '/ember-cli-tinymce/tinymce/plugins/table/plugin.min.js',
        textcolor: '/ember-cli-tinymce/tinymce/plugins/textcolor/plugin.min.js',
        colorpicker: '/ember-cli-tinymce/tinymce/plugins/colorpicker/plugin.min.js',
        code: '/ember-cli-tinymce/tinymce/plugins/code/plugin.min.js',
        preview: '/ember-cli-tinymce/tinymce/plugins/preview/plugin.min.js'
      },
      menubar: true,
      toolbar1: 'insertfile undo redo | styleselect fontselect fontsizeselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code preview'
    },

    setup: (function () {
      var _this = this;

      var defaults = this.get('defaults');
      var extraOptions = this.get('options');
      var options = _ember['default'].merge(defaults, extraOptions);

      _ember['default'].merge(options, {
        setup: function setup(editor) {
          _this.get('extraButtons').forEach(function (button) {
            editor.addButton(button, {
              text: button,
              icon: false,
              classes: 'tinymce-extraButton',
              onclick: function onclick() {
                editor.insertContent('&nbsp;{{' + button + '}}&nbsp;');
              }
            });
          });

          _this.get('conditionals').forEach(function (button) {
            editor.addButton(button, {
              text: button,
              icon: false,
              classes: 'tinymce-extraButton tinymec-extraButton--conditional',
              onclick: function onclick() {
                editor.insertContent('<p>[[' + button + ']]</p><p></p><p>[[/' + button + ']]</p>');
              }
            });
          });

          _this.set('editor', editor);
          editor.on('change', function () {
            _this.set('value', editor.getContent());
          });
        }
      });

      this.$('textarea').tinymce(options);
    }).on('didRender'),

    valueChanged: _ember['default'].computed('value', function () {
      var _this2 = this;

      tinymce.editors.filter(function (editor) {
        return editor.id === _this2.get('editor').id;
      }).forEach(function (editor) {
        editor.setContent(this.get('value'));
      });
    })
  });
});