define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 8
          }
        },
        "moduleName": "modules/ember-toggle/components/x-toggle-switch/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "class", "x-toggle");
        dom.setAttribute(el1, "type", "checkbox");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "aria-role", "checkbox");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element0, [], true);
        }
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [1]);
        var morphs = new Array(11);
        morphs[0] = dom.createAttrMorph(element0, 'checked');
        morphs[1] = dom.createAttrMorph(element0, 'disabled');
        morphs[2] = dom.createAttrMorph(element0, 'id');
        morphs[3] = dom.createAttrMorph(element0, 'name');
        morphs[4] = dom.createAttrMorph(element0, 'onchange');
        morphs[5] = dom.createAttrMorph(element1, 'for');
        morphs[6] = dom.createAttrMorph(element2, 'aria-owns');
        morphs[7] = dom.createAttrMorph(element2, 'class');
        morphs[8] = dom.createAttrMorph(element2, 'data-tg-on');
        morphs[9] = dom.createAttrMorph(element2, 'data-tg-off');
        morphs[10] = dom.createAttrMorph(element2, 'id');
        return morphs;
      },
      statements: [["attribute", "checked", ["get", "toggled", ["loc", [null, [2, 12], [2, 19]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [3, 13], [3, 21]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["get", "forId", ["loc", [null, [5, 7], [5, 12]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["get", "name", ["loc", [null, [6, 9], [6, 13]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onchange", ["subexpr", "action", [["get", "sendToggle", ["loc", [null, [7, 20], [7, 30]]], 0, 0, 0, 0]], ["value", "target.checked"], ["loc", [null, [null, null], [7, 55]]], 0, 0], 0, 0, 0, 0], ["attribute", "for", ["concat", [["get", "effectiveForId", ["loc", [null, [10, 14], [10, 28]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-owns", ["concat", [["get", "forId", ["loc", [null, [12, 17], [12, 22]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["x-toggle-btn ", ["get", "themeClass", ["loc", [null, [14, 26], [14, 36]]], 0, 0, 0, 0], " ", ["get", "size", ["loc", [null, [14, 41], [14, 45]]], 0, 0, 0, 0], ["subexpr", "if", [["get", "disabled", ["loc", [null, [14, 52], [14, 60]]], 0, 0, 0, 0], " x-toggle-disabled"], [], ["loc", [null, [14, 47], [14, 83]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-tg-on", ["concat", [["get", "onLabel", ["loc", [null, [15, 18], [15, 25]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-tg-off", ["concat", [["get", "offLabel", ["loc", [null, [16, 19], [16, 27]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["concat", ["x-toggle-visual-", ["get", "forId", ["loc", [null, [17, 26], [17, 31]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});